<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b pa-1 pa-md-2 pa-lg-4 pa-xl-4">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">支付测试</span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm"></span>
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pa-1 pa-md-10 pa-xl-16">
      <div class="tab-content">
        <b-row>
          <b-col sm="0" md="1" lg="1" xl="1" cols="0"></b-col>
          <b-col sm="12" md="9" lg="9" xl="9" cols="12">
            <b-form id="takeOrderForm" method="POST" class="form" novalidate="novalidate">

              <b-row class="mt-2">
                <b-col cols="3" class="text-right">
                  <label class="form-group-label"><span class="text-danger font-weight-bold">*</span>
                    支付方式:</label>
                </b-col>
                <b-col cols="8">
                  <b-form-group class="mb-0">
                    <b-form-select v-model="formModel.payType">
                      <option :value="101">微信支付</option>
                      <option :value="201">支付宝</option>
                      <option :value="301">印度Pay</option>
                      <option :value="401">巴西Pay</option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-1">
                <b-col cols="3" class="text-right">
                  <label class="form-group-label"><span class="text-danger font-weight-bold">*</span>交易金额:</label>
                </b-col>
                <b-col cols="8">
                  <b-form-group class="mb-0">
                    <b-form-input name="amount" type="number" v-model="formModel.amount" placeholder="请输入交易金额...">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-1">
                <b-col cols="3" class="text-right">
                  <label class="form-group-label"><span class="text-danger font-weight-bold">*</span>商品名称:</label>
                </b-col>
                <b-col cols="8">
                  <b-form-group class="mb-0">
                    <b-form-input name="product" v-model="formModel.product" placeholder="请输入商品名称..."></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>


              <b-row class="mt-1">
                <b-col cols="3" class="text-right">
                  <label class="form-group-label">商品描述:</label>
                </b-col>
                <b-col cols="8">
                  <b-form-group class="mb-0">
                    <b-form-textarea name="describe" rows="2" v-model="formModel.describe" placeholder="商品描述...">
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>


              <b-row class="mt-1">
                <b-col cols="3" class="text-right">
                  <label class="form-group-label"><span class="text-danger font-weight-bold">*</span>
                    付款人手机号:</label>
                </b-col>
                <b-col cols="8">
                  <b-form-group class="mb-0">
                    <b-form-input name="payerPhone" v-model="formModel.payerPhone" placeholder="请输入付款人手机号...">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-1">
                <b-col cols="3" class="text-right">
                  <label class="form-group-label"><span class="text-danger font-weight-bold">*</span>
                    付款人邮箱:</label>
                </b-col>
                <b-col cols="8">
                  <b-form-group class="mb-0">
                    <b-form-input name="payerEmail" v-model="formModel.payerEmail" placeholder="请输入付款人邮箱...">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-1">
                <b-col cols="3" class="text-right">
                  <label class="form-group-label"><span class="text-danger font-weight-bold">*</span>
                    跳转url:</label>
                </b-col>
                <b-col cols="8">
                  <b-form-group class="mb-0">
                    <b-form-input name="returnUrl" v-model="formModel.returnUrl" placeholder="请输入跳转url...">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-1">
                <b-col cols="3" class="text-right">
                  <label class="form-group-label"><span class="text-danger font-weight-bold">*</span>
                    extendInfo:</label>
                </b-col>
                <b-col cols="8">
                  <b-form-group class="mb-0">
                    <b-form-input name="extendInfo" v-model="formModel.extendInfo" placeholder="请输入extendInfo...">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-1">
                <b-col cols="3"></b-col>
                <b-col cols="8" class="text-align:right">
                  <b-row class="mt-1">
                    <b-col cols="6" class="px-3">
                      <v-btn class="w-100" :loading="loading" :disabled="loading" color="success" @click="onSubmit">
                        下单
                        <template v-slot:loader>
                          <span>处理中...</span>
                        </template>
                      </v-btn>
                      <!-- <b-button class="w-100" v-on:click="onSubmit" variant="primary">下单
                      </b-button> -->
                    </b-col>
                    <b-col cols="6" class="px-3">
                      <b-button class="w-100" v-on:click="onReset" variant="danger">重置
                      </b-button>
                    </b-col>


                  </b-row>


                </b-col>
              </b-row>
            </b-form>
          </b-col>
          <b-col sm="0" md="2" lg="2" xl="2" cols="0"></b-col>
        </b-row>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
import KTUtil from "@/assets/js/components/util";

import ApiService from "@/core/services/api.service";

// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";

export default {
  name: "TakeOrder",
  components: {
    KTCard,
  },
  data() {
    return {
      loading:false,
      formModel: {
        payType: 301,
        payerPhone: "8960529686",
        payerEmail: "payer@gmail.com",
        returnUrl: "https://www.baidu.com"
      },
    };
  },
  created() {

  },
  mounted() {
    // Create a FormValidation instance
    let takeOrderForm = KTUtil.getById("takeOrderForm");
    this.fv = formValidation(takeOrderForm, {
      fields: {
        payType: {
          validators: {
            notEmpty: {
              message: "支付方式不能为空!",
            },
          },
        },
        product: {
          validators: {
            notEmpty: {
              message: "请选择商品名称!",
            },
          },
        },

        describe: {
          validators: {
            notEmpty: {
              message: "请输入商品描述!",
            },
          },
        },
        amount: {
          validators: {
            notEmpty: {
              message: '订单金额不能为空!'
            },
          }
        },
        payerPhone: {
          validators: {
            notEmpty: {
              message: "请输入付款人手机号!",
            },
          },
        },
        payerEmail: {
          validators: {
            notEmpty: {
              message: "请输入付款人邮箱!",
            },
          },
        },
        returnUrl: {
          validators: {
            notEmpty: {
              message: "请输入前台跳转url!",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
      },
    });
  },
  methods: {
    onReset() {
      this.formModel = {
        payType: 301,
        payerPhone: "8960529686",
        payerEmail: "payer@gmail.com",
        returnUrl: "https://www.baidu.com",
        extendInfo:"",
      };
    },
    onSubmit() {
      let that = this;
      this.fv.validate().then(function (status) {
        if (status === "Valid") {
          that.loading=true;
          ApiService.post("/api/order/manage/dial/test", that.formModel).then(
            ({ data }) => {
              if (data.success) {
                let result = data.result;
                if (result) {
                  let code = result.code;
                  if (code == "0") {
                    let payUrl = result.payUrl;
                    window.open(payUrl,"_blank");
                    Swal.fire({
                      title: "下单成功！",
                      text: result.message,
                      icon: "success",
                      confirmButtonClass: "btn btn-success",
                      heightAuto: false,
                      timer: 3000,
                    });
                  } else {
                    Swal.fire({
                      title: "下单失败:[" + code + "]",
                      text: result.message,
                      icon: "warning",
                      confirmButtonClass: "btn btn-success",
                      heightAuto: false,
                      timer: 3000,
                    });
                  }
                }
                that.loading=false;
              } else {
                that.loading=false;
                Swal.fire({
                  title: "下单失败！",
                  text: data.message,
                  icon: "warning",
                  confirmButtonClass: "btn btn-success",
                  heightAuto: false,
                  timer: 3000,
                });
              }
            }
          );
        }
      });
    },
  },
  computed: {},
  watch: {

  },
};
</script>
<style>
</style>